import {Accordion} from "react-bootstrap";
import React from "react";
import SidebarButton from "./SidebarButton";

const SidebarItem = (props) => {

    return (
        <Accordion.Item eventKey={props.eventKey}>
            <Accordion.Header>{props.label}</Accordion.Header>
            <Accordion.Body>
                {props.buttons.map(button => (
                    button ?
                        <SidebarButton url={props.url.replace('<id>', button.id)} label={button.label}/>
                        : <br/>
                ))}
            </Accordion.Body>
        </Accordion.Item>
    )

}

export default SidebarItem