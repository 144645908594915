import {useContext, useEffect, useState} from "react";
import {AsyncContext, AuthContext} from "../context";

const useHeaders = (props) => {

    const [labels, setLabels] = useState({
        num: '№ п/п',
        last_name: 'Фамилия',
        first_name: 'Имя',
        second_name: 'Отчество',
        sex: 'Пол',
        email: 'Электронная почта',
        official_address_str: 'Адрес прописки',
        residential_address_str: 'Адрес проживания',
        birth_date: 'Дата рождения'
    })

    const [order, setOrder] = useState([
        'num',
        'last_name',
        'first_name',
        'second_name',
        'sex',
        'email',
        'official_address_str',
        'residential_address_str',
        'birth_date'
    ])

    const [visible, setVisible] = useState(order.reduce((acc, key) => {
            acc[key] = true
            return acc
        }, {}
    ))


    let documentTypes = null
    let contactTypes = null

    const update = () => {
        if (documentTypes === null || contactTypes === null)
            return
        let newLabels = labels
        let newOrder = order
        let newVisible = visible
        documentTypes.forEach((documentType) => {
            newLabels[`document${documentType.id}`] = documentType.name
            newOrder.push(`document${documentType.id}`)
            newVisible[`document${documentType.id}`] = true
        })
        contactTypes.forEach((contactType) => {
            newLabels[`contact${contactType.id}`] = contactType.name
            newOrder.push(`contact${contactType.id}`)
            newVisible[`contact${contactType.id}`] = true
        })
        setLabels(newLabels)
        setOrder(newOrder)
        setVisible(newVisible)
    }

    useEffect(() => {
        if (!props.token)
            return

        props.request(props.token, 'GET', '/org/type/document/', {}, {}, (response) => {
            if (documentTypes !== null)
                return
            documentTypes = response.data
            update()
        }, {})

        props.request(props.token, 'GET', '/org/type/contact/', {}, {}, (response) => {
            if (contactTypes !== null)
                return
            contactTypes = response.data
            update()
        }, {})
    }, [props.token])

    return {order, labels, visible, setVisible}
}

export default useHeaders