import './App.scss';

// import Navbar from "./UI/Navbar";
import {AsyncContext, AuthContext, TableContext} from "./context";
import {BrowserRouter} from "react-router-dom";
import AppRouter from "./UI/AppRouter";
import useRequest from "./hooks/useRequest";
import Loader from "./UI/Loader";
import {useEffect, useState} from "react";
import useHeaders from "./hooks/useHeaders";

function App() {
    const [isLoading, request] = useRequest();
    const [token, setToken] = useState('');
    const [userId, setUserId] = useState(0);
    const [user, setUser] = useState(null);

    const headers = useHeaders(token, request)
    const [data, setData] = useState()
    //TODO make study year calculation
    const [studyYear, setStudyYear] = useState(2023)

    useEffect(() => {
        if (localStorage.getItem('token')) {
            setToken(localStorage.getItem('token'));
            setUserId(localStorage.getItem('user_id'));
        }
    }, []);

    return (
        <AsyncContext.Provider value={{isLoading, request}}>
            <AuthContext.Provider value={{token, setToken, userId, setUserId, user, setUser}}>
                <TableContext.Provider value={{headers, data, setData, studyYear, setStudyYear}}>
                    <BrowserRouter>
                        {isLoading ? <Loader/> : ''}
                        <AppRouter/>
                    </BrowserRouter>
                </TableContext.Provider>
            </AuthContext.Provider>
        </AsyncContext.Provider>
    );
}

export default App;
