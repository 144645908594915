import {useContext, useState} from "react";
import {AsyncContext, AuthContext} from "../context";
import {Button, Card, CardBody, CardHeader, Form} from 'react-bootstrap';
import './styles/Login.scss';

const Login = () => {
    const {setToken, setUserId} = useContext(AuthContext);
    const {request} = useContext(AsyncContext);
    const [errors, setErrors] = useState();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [validated] = useState(false);

    const login = (event) => {
        event.preventDefault();
        request('', 'POST', '/login/', {}, {'username': username, 'password': password}, loginCallback, {});
    }


    const loginCallback = (response) => {
        if (response.status === 200) {
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('user_id', response.data.user_id);
            setToken(response.data.token);
            setUserId(response.data.user_id);
        } else {
            setToken('');
            setUserId(0);
            if (response.status === 400)
                setErrors(response.data.non_field_errors[0])
            else
                setErrors('Непредвиденная ошибка')
        }
    }


    return (
        <div className="full-page login">
            <Form validated={validated} onSubmit={login}>
                <Card className="card login m-auto text-center">
                    <CardHeader className="bg-dark text-light">
                        Авторизация
                    </CardHeader>
                    <CardBody className="card-body">
                        <Form.Group className="mb-3" controlId="loginform_username">
                            <Form.Label>Имя пользователя</Form.Label>
                            <Form.Control required type="text" value={username} onChange={e => {
                                setUsername(e.target.value)
                            }}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="loginform_password">
                            <Form.Label>Пароль</Form.Label>
                            <Form.Control required type="password" value={password} onChange={e => {
                                setPassword(e.target.value)
                            }}/>
                        </Form.Group>
                        {errors? <div className="error">{errors}</div> : ''}
                    </CardBody>
                    <Button variant="primary" type="submit" className="card-footer text-light bg-primary border-0">
                        Войти
                    </Button>
                </Card>
            </Form>
        </div>
    )
}

export default Login;