import React, {useContext, useState} from "react";
import { Col, Container, Row} from "react-bootstrap";
import Topbar from "../UI/Topbar";
import Sidebar from "../UI/Sidebar";
import Toolbar from "../UI/Toolbar";
import './styles/Main.scss';
import ModalExport from "../UI/ModalExport";
import useHeaders from "../hooks/useHeaders";
import UserTable from "../UI/UserTable";
import {TableContext} from "../context";

const Main = () => {
    const {headers, data, setData} = useContext(TableContext)

    const [showExportModal, setShowExportModal] = useState(false);
    const handleShowExportModal = () => setShowExportModal(true);

    const updateVisible = (newVisible) => {
        setData(newVisible.data);
    };

    const formatExport = () => {
        handleShowExportModal();
    }

    return (
        <div className={"full-page overflow-hidden"}>
            <Topbar/>
            <div className="m-3">
                <Container fluid>
                    <Row>
                        <Col className={'overflow-x-auto'} style={{height: '90vh'}}>
                            <Sidebar updateVisible={updateVisible}/>
                        </Col>
                        <Col xs={9}>
                            <Row>
                                <Toolbar
                                    formatExport={formatExport}
                                    headers={headers}
                                />
                            </Row>
                            <Row className="table-responsive overflow-auto"  style={{maxHeight: '85vh'}}>
                                <UserTable
                                    data={data}
                                    headers={headers}
                                />
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>

            {showExportModal && <ModalExport
                headers={headers}
                data={data}
                setShowExportModal={setShowExportModal}
            />}
        </div>
    );
}

export default Main;
