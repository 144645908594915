import React, {useContext, useEffect} from 'react';
import {Route, Routes} from "react-router-dom";
import {AsyncContext, AuthContext} from "../context";
import Main from "../pages/Main";
import Login from "../pages/Login";
import Profile from "../pages/Profile";

const AppRouter = () => {
    const {token, userId, user, setUser} = useContext(AuthContext);
    const {request} = useContext(AsyncContext);

    const callback = response => {
        if (response.status === 200)
            setUser(response.data);
        else
            setUser(null);
    }

    useEffect(() => {
        if (token && userId)
            request(token, 'GET', `/users/${userId}/`, {}, {}, callback, {});
    }, [token, userId]);


    if (user === null)
        return (
            <Routes>
                <Route key={'/'} path={'/'} element={<Login/>} exact={true}/>
            </Routes>
        );
    return (
        <Routes>
            <Route key={'/'} path={'/'} element={<Main/>} exact={true}/>
            <Route path='/profile' element={<Profile nav={true} userModal={user}/>} />
        </Routes>
    )
};

export default AppRouter;
