import {Button} from "react-bootstrap";
import {useContext} from "react";
import {AsyncContext, AuthContext, TableContext} from "../context";

const SidebarButton = (props) => {
    const {token} = useContext(AuthContext);
    const {request} = useContext(AsyncContext);
    const {setData} = useContext(TableContext)
    const onClick = () => {
        request(token, 'GET', props.url, {}, {}, (response) => {
            setData(response.data)
        }, {})
    }

    return (
        <Button className='me-2 mb-2' onClick={onClick}>
            {props.label}
        </Button>
    )
}

export default SidebarButton