import React from "react";
import {Button, ButtonGroup,  Dropdown, Form} from "react-bootstrap";

const Toolbar = ({formatExport, headers}) => {

    const toggleVisible = (label) => {
        headers.setVisible(prevState => ({
            ...prevState,
            [label]: !headers.visible[label]
        }))
    }

    return (
        <div>
            <Dropdown as={ButtonGroup} className="dropdown-menu-fixed me-2 mb-2">
                <Dropdown.Toggle id="dropdown-basic-button">
                    Видимые столбцы
                </Dropdown.Toggle>
                <Dropdown.Menu className={'p-2'}>
                    <Form>
                        {headers.order.map((label, index) => (
                            <Form.Check
                                key={index}
                                type="checkbox"
                                label={<span className="checkbox-label">{headers.labels[label]}</span>}
                                checked={headers.visible[label]}
                                onChange={() => toggleVisible(label)}
                            />
                        ))}
                    </Form>
                </Dropdown.Menu>
            </Dropdown>
            <Button className={'me-2 mb-2'} variant={'primary'} onClick={formatExport}>
                Экспорт видимого
            </Button>
        </div>
    );
}

export default Toolbar;
