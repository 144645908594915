import {Col, Row, Form} from "react-bootstrap";
import {useContext, useEffect, useState} from "react";
import {AsyncContext, AuthContext} from "../context";

const DocumentInput = (props) => {
    const {token} = useContext(AuthContext);
    const {request} = useContext(AsyncContext);

    const [documentList, setDocumentList] = useState()
    const [documentTypeList, setDocumentTypeList] = useState([])
    const [document, setDocument] = useState()

    useEffect(() => {
        request(token, 'GET', `/org/type/document/`, {}, {}, documentTypeListCallback, {})
    }, []);

    const documentTypeListCallback = (response) => {
        setDocumentTypeList([...response.data.map(responseItem => (
            responseItem.name
            )
        )])
    }

    let makeOnChange = (field, indexGlobal, index) => {
        let new_func = (e) => {
            let updateDocument = [...props.documents]
            updateDocument[indexGlobal].value[index].value = e.target.value
            props.set_document(updateDocument)
        }
        return new_func
    }

    return (
        props.documents.map((document, indexGlobal) => (
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>Тип документа</Form.Label>
                    <Form.Select
                        defaultValue={document.type.name}
                    >
                        {
                            documentTypeList.map(documentTypeListItem => (
                                <option value={documentTypeListItem}>{documentTypeListItem}</option>
                            ))
                        }
                    </Form.Select>
                </Form.Group>
                {
                    Object.keys(document.type.configuration.fields).map((k, index) => (
                        <Form.Group as={Col}>
                            <Form.Label>
                                {document.type.configuration.fields[k].label.charAt(0).toUpperCase() + document.type.configuration.fields[k].label.slice(1).toLowerCase()}
                            </Form.Label>
                            <Form.Control
                                type='text'
                                defaultValue={document.value[index].value}
                                onChange={makeOnChange(document.value[index].field, indexGlobal, index)}
                            />
                        </Form.Group>
                    ))
                }
            </Row>
        ))
    )
}

export default DocumentInput
