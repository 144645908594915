import React from "react";
import {Col, Form} from "react-bootstrap";


const ContactInput = (props) => {

    let makeOnChange = (field, index) => {
        let new_func = (e) => {
            let updatedContact = [...props.contacts]
            updatedContact[index].value = e.target.value
            props.set_contact(updatedContact)
        }
        return new_func;
    }

    if (typeof props.contacts === 'undefined')
        return ""

    return (
        props.contacts.map((contact, index) => (
            <Form.Group as={Col}>
                <Form.Label>{contact.type.name + ' ' + (index + 1)}</Form.Label>
                <Form.Control
                    type='text'
                    name={'contact' + (index + 1)}
                    defaultValue={contact.value}
                    onChange={makeOnChange('value', index)}
                />
            </Form.Group>
        ))
    )
}

export default ContactInput
