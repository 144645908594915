import axios from "axios";
import {useState} from "react";

const useRequest = () => {
    const [isLoading, setIsLoading] = useState(false);

    const request = (token, method, url, params, data, callback, headers, responseType='json') => {
        const local_callback = (response) => {
            setIsLoading(false);
            callback(response);
        }

        let _headers = {
            'Content-type': 'application/json',
        }
        if (token) {
            _headers.Authorization = 'Token ' + token
        }

        Object.entries(headers).forEach(([k, v]) => {
            _headers[k] = v;
        });

        setIsLoading(true);

        axios({
            'method': method,
            'baseURL': 'https://back.lyceum-db.tofmal.slezins.ru/',
            'url': '/api' + url,
            'params': params,
            'data': data,
            'headers': _headers,
            'responseType': responseType
        })
            .then(local_callback)
            .catch((error) => local_callback(error.response));
    }

    return [isLoading, request]
};

export default useRequest;
