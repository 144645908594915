import Topbar from "../UI/Topbar";
import React, {useContext, useEffect, useState} from "react";
import {AsyncContext, AuthContext} from "../context";
import {Button, Col, Form, Row, Tab, Tabs} from "react-bootstrap";
import AddressInput from "../UI/AddressInput";
import ContactInput from "../UI/ContactInput";
import DocumentInput from "../UI/DocumentInput";

const Profile = (props) => {
    const {user, userId, token} = useContext(AuthContext);
    const {request} = useContext(AsyncContext);
    const [validated, setValidated] = useState(false);

    const [lastName, setLastName] = useState(user.last_name);
    const [firstName, setFirstName] = useState(user.first_name);
    const [email, setEmail] = useState(user.email);
    const [secondName, setSecondName] = useState(user.second_name);
    const [officialAddress, setOfficialAddress] = useState(user.official_address)
    const [officialIsResidentialFlag, setOfficialIsResidentialFlag] = useState(user.official_is_residential_flag);
    const [residentialAddress, setResidentialAddress] = useState(user.residential_address)
    const [contacts, setContacts] = useState(user.contacts)
    const [documents, setDocuments] = useState(user.documents)

    const profile = (event) => {
        event.preventDefault();
        const data = {
            'username': user.username,
            'last_name': lastName,
            'first_name': firstName,
            'email': email,
            'second_name': secondName,
            'official_address': officialAddress,
            'official_is_residential_flag': officialIsResidentialFlag,
            'residential_address': residentialAddress,
            'contacts': contacts.map(({type, ...other}) => ({
                ...other,
                "type_id": type.id
            })),
            'documents': documents
        }
        request(token, 'PATCH', `/people/users/${userId}/`, {}, data, profileCallback, {});
    }

    const profileCallback = () => {
    }

    const officialIsResidentialFlagHandler = () => {
        setOfficialIsResidentialFlag(!officialIsResidentialFlag)
    }

    return (
        <div className="full-page">
            {props.nav ? <Topbar />: ''}
            <div className="m-3">
                <h1 className='text-center'>{lastName + ' ' + firstName + ' ' + secondName}</h1>
                <Form validated={validated} onSubmit={profile}>
                    <Tabs defaultActiveKey='main' id='main' fill>

                        <Tab eventKey='main' title='Основное'>
                            <h4 className='text-center'>Основная информация</h4>
                            <Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Фамилия</Form.Label>
                                    <Form.Control type='text' name='last_name' defaultValue={lastName}
                                                  onChange={(e) => setLastName(e.target.value)}/>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Имя</Form.Label>
                                    <Form.Control type='text' name='first_name' defaultValue={firstName}
                                                  onChange={(e) => setFirstName(e.target.value)}/>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Отчество</Form.Label>
                                    <Form.Control type='text' name='second_name' defaultValue={secondName}
                                                  onChange={(e) => setSecondName(e.target.value)}/>
                                </Form.Group>
                            </Row>
                        </Tab>

                        <Tab eventKey='contact' title='Контакты'>
                            <h4 className='text-center'>Контакты</h4>
                            <Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Электронная почта</Form.Label>
                                    <Form.Control type='text' name='email' defaultValue={email}
                                                  onChange={(e) => setEmail(e.target.value)}/>
                                </Form.Group>
                                <ContactInput
                                    set_contact={setContacts}
                                    contacts={contacts}
                                />
                            </Row>
                        </Tab>

                        <Tab eventKey='address' title='Адреса'>
                            <h4 className='text-center'>Адреса</h4>
                            <h5 className='text-center'>Адрес прописки</h5>
                            <AddressInput
                                set_address={setOfficialAddress}
                                address={officialAddress}
                            />
                            <Row className='m-3'>
                                <Form.Check type='checkbox' id={officialIsResidentialFlag}
                                            label='Адрес прописки совпадает с адресом проживания.'
                                            checked={officialIsResidentialFlag}
                                            onChange={officialIsResidentialFlagHandler}
                                />
                            </Row>
                            {!officialIsResidentialFlag ?
                                <AddressInput set_address={setResidentialAddress} address={residentialAddress}/> : ''}
                        </Tab>

                        <Tab eventKey='documents' title='Документы'>
                            <DocumentInput
                                documents={documents}
                                set_document={setDocuments}
                            />
                        </Tab>
                    </Tabs>
                    <br/>
                    <Button variant="primary" type="submit" className="mt-3">
                        Сохранить
                    </Button>
                </Form>
            </div>
        </div>
    )
}

export default Profile;