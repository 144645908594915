import LogoutButton from "./LogoutButton";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './styles/Toolbar.scss';
import React from "react";

const Topbar = () => {
    return (
        <Navbar expand='lg' className='bg-body-tertiary'>
            <Container fluid>
                <Navbar.Brand href='/'>Лицей №14. БД</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href='/profile'>Профиль</Nav.Link>
                    </Nav>
                    <LogoutButton/>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Topbar;
