import {Button, Modal} from "react-bootstrap";
import React, {useContext, useEffect, useState} from "react";
import {AsyncContext, AuthContext} from "../context";

const ModalExport = (props) => {

    const {token} = useContext(AuthContext);
    const {request} = useContext(AsyncContext);

    const [exportFormats, setExportFormats] = useState()

    const exportVisible = (format) => {

        let exportOrder = props.headers.order.map(orderItem => {
            if (props.headers.visible[orderItem])
                return orderItem
        })
        exportOrder = exportOrder.filter(e => e !== undefined)

        let exportLabel = {}
        Object.entries(props.headers.labels).forEach(([key, value]) => {
            if(props.headers.visible[key])
                exportLabel[key] = value
        })

        let exportData = props.data.map((row, index) => {
            let exportRow = {}
            exportRow['num'] = index + 1
            Object.entries(row).forEach(([key, value]) => {
                if(props.headers.visible[key])
                    if (key === 'num')
                        exportRow[key] = index + 1
                    else
                        exportRow[key] = value
            })
            return exportRow
        })

        request(token, 'POST', '/export/', {}, {
            format: format,
            order: exportOrder,
            labels: exportLabel,
            data: exportData
        }, ExportCallback, {}, 'blob')
    }

    const ExportCallback = (response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "export.xlsx";
        alink.click();
    }


    useEffect(() => {
        request(token, 'GET', '/export/formats/', {}, {}, (response) => {
            setExportFormats(response.data)
        }, {});
    }, []);


    return (
        <Modal show={true} onHide={() => props.setShowExportModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Выберите формат для экспорта.</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {exportFormats && Object.entries(exportFormats).map(([key, value]) => (
                    <Button key={key} variant="primary" className={'me-2 mb-2'} onClick={() => exportVisible(key)}>
                        {value}
                    </Button>
                ))}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => props.setShowExportModal(false)}>
                    Отмена
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalExport