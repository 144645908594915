import {Table} from "react-bootstrap";
import React from "react";

const UserTable = ({data, headers}) => {

    return (
        <Table bordered hover className="text-nowrap">
            <thead className={'sticky-header'}>
            <tr>
                {headers.order.map((key) => (
                    headers.visible[key] && (
                        <th key={key} className={'align-center'}>
                            {headers.labels[key]}
                        </th>
                    )
                ))}
            </tr>
            </thead>
            <tbody>
            {data && data.map((item, index) => (
                <tr key={item.id}>
                    {headers.order.map((key) => {
                        if (headers.visible[key]) {
                            let content = '';
                            if (key === 'num') content = index + 1;
                            else if (key.includes('contact')) content = item.contacts.map(contact => {
                                return contact.value
                            })
                            else if (key === 'birth_date') content = item.birth_date && Intl.DateTimeFormat().format(new Date(item.birth_date));
                            else if (key.includes('document') && item.documents.length > 0) content = item.documents.map(document => {
                                if (document.type_id === parseInt(key.slice(8)))
                                    return document.string_value
                            })
                            else content = item[key];
                            return <td key={key}>{content}</td>;
                        }
                        return null;
                    })}
                </tr>
            ))}
            </tbody>
        </Table>
    )
}

export default UserTable