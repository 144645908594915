import {Col, Form, Row} from "react-bootstrap";
import React, {useContext, useEffect, useState} from "react";
import SelectTypeList from "./SelectTypeList";
import {AsyncContext, AuthContext} from "../context";

const AddressInput = (props) => {

    const {token} = useContext(AuthContext);
    const {request} = useContext(AsyncContext);
    const [localityTypeList, setLocalityTypeList] = useState();
    const [streetTypeList, setStreetTypeList] = useState();
    const [localityType, setLocalityType] = useState(props.address.locality_type);
    const [streetType, setStreetType] = useState(props.address.street_type);

    useEffect(() => {
        request(token, 'GET', `/org/type/locality/`, {}, {}, localityTypeListCallback, {});
        request(token, 'GET', `/org/type/street/`, {}, {}, streetTypeListCallback, {});
    }, []);

    const localityTypeListCallback = (response) => {
        setLocalityTypeList(response.data);
    }

    const streetTypeListCallback = (response) => {
        setStreetTypeList(response.data)
    }

    let makeOnChange = (field) => {
        let new_func = (e) => {
            let address = {...props.address}
            address[field] = e.target.value
            props.set_address(address)
        }
        return new_func;
    }

    if (typeof props.address === 'undefined')
        return ""

    return (
        <div>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>Тип населенного пункта</Form.Label>
                    <SelectTypeList
                        set_type={setLocalityType}
                        list={localityTypeList}
                        temp_type={props.address.locality_type}
                        type_name='locality_type'
                    />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Населенный пункт</Form.Label>
                    <Form.Control
                        type='text' name='locality'
                        value={props.address.locality}
                        onChange={makeOnChange("locality")}
                    />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Тип улицы</Form.Label>
                    <SelectTypeList
                        set_type={setStreetType}
                        list={streetTypeList}
                        temp_type={props.address.street_type}
                        type_name='street_type'
                    />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Улица</Form.Label>
                    <Form.Control
                        type='text' name='street'
                        value={props.address.street}
                        onChange={makeOnChange("street")}
                    />
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>Дом</Form.Label>
                    <Form.Control
                        type='text' name='house'
                        value={props.address.house}
                        onChange={makeOnChange("house")}
                    />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Корпус</Form.Label>
                    <Form.Control
                        type='text' name='frame'
                        value={props.address.frame}
                        onChange={makeOnChange("frame")}
                    />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Квартира</Form.Label>
                    <Form.Control
                        type='text' name='apartment'
                        value={props.address.apartment}
                        onChange={makeOnChange("apartment")}
                    />
                </Form.Group>
            </Row>
        </div>
    )
}

export default AddressInput
