import {Button} from "react-bootstrap";
import {useContext} from "react";
import {AuthContext} from "../context";

const LogoutButton = () => {
    const {setToken, setUserId, setUser} = useContext(AuthContext);

    const logout = () => {
        setToken('');
        setUserId(0);
        setUser(null);
        localStorage.removeItem('token');
        localStorage.removeItem('user_id');
        window.location = '/'
    };

    return (
        <Button variant='primary' onClick={logout}>
            Выйти
        </Button>
    )
};

export default LogoutButton;