import React from "react";
import './styles/Loader.scss';

const Loader = () => {
    return (
        <div className="full-page loader-container">
            <div className="loader-spinner">
            </div>
        </div>
    )
}

export default Loader