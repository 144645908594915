import {Accordion} from "react-bootstrap";
import React, {useContext, useEffect, useState} from "react";
import {AsyncContext, AuthContext, TableContext} from "../context";
import SidebarItem from "./SidebarItem";
import SidebarButton from "./SidebarButton";

const Sidebar = (props) => {
    const {token} = useContext(AuthContext);
    const {request} = useContext(AsyncContext);
    const {studyYear} = useContext(TableContext)

    const [aspirantsClasses, setAspirantsClasses] = useState([])
    const [studentsClasses, setStudentsClasses] = useState([])
    const [departments, setDepartments] = useState([])

    const makeButtons = (data, setButtons) => {
        let buttons = data.map(row => {
            return {label: row.name, id: row.id, rowIndex: row.number || 1}
        })
        if (!buttons.length)
            return
        let newButtons = []
        let prevIndex = buttons[0].rowIndex
        buttons.forEach(button => {
            if (button.rowIndex !== prevIndex) {
                prevIndex = button.rowIndex
                newButtons.push(null)
            }
            newButtons.push(button)
        })
        setButtons(newButtons)
    }

    const loadButtons = (buttons, setButtons, url, params) => {
        if (buttons.length)
            return
        request(token, 'GET', url, params, {}, (response) => {
            makeButtons(response.data, setButtons)
        }, {})
    }

    useEffect(() => {
        loadButtons(aspirantsClasses, setAspirantsClasses, '/groups/classes/', {for_aspirants: true, study_year: studyYear})
        loadButtons(studentsClasses, setStudentsClasses, '/groups/classes/', {for_aspirants: false, study_year: studyYear})
        loadButtons(departments, setDepartments, '/groups/departments/', {})
    }, [token])

    return (
        <Accordion flush alwaysOpen>
            <SidebarButton url="/people/employees" label="Все учителя"/>
            <SidebarButton url={`/people/pupils/?classes__study_year=${studyYear}`} label="Все ученики"/>

            <SidebarItem
                buttons={aspirantsClasses}
                url="/groups/classes/<id>/pupils"
                label="Абитуриенты"
                eventKey="aspirants"
            />
            <SidebarItem
                buttons={studentsClasses}
                url="/groups/classes/<id>/pupils"
                label="Ученики"
                eventKey="pupils"
            />
            <SidebarItem
                buttons={departments}
                url="/groups/departments/<id>/employees"
                label="Сотрудники"
                eventKey="departments"
            />

        </Accordion>
    );
};

export default Sidebar;
