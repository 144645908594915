import {Form} from "react-bootstrap";
import React from "react";

const SelectTypeList = (props) => {

    let makeOnChange = (field) => {
        let new_func = (e) => {
            let list = {...props.list}
            list[field] = e.target.value
            props.set_type(list[field])
        }
        return new_func;
    }

    if (typeof props.list === 'undefined')
        return ""

    return (
        <Form.Select
            onChange={makeOnChange(props.type_name)}
            defaultValue={props.temp_type}
        >
            {
                props.list.map(item => (
                    <option
                        value={item.name}
                        key={item.id}
                    >
                        {item.name}
                    </option>
                ))
            }
        </Form.Select>
    )
}

export default SelectTypeList
